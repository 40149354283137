import { EventCategory, HitType, useFlagship } from "@flagship.io/react-sdk";
import { memo, useCallback, useContext, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { destinationResortFilterOptions, isServerSide, sortOptions } from "app/utils/utils";
import {
	defaultValues,
	validateDepartureDate,
	validateDestination,
	validateSearch,
} from "app/pages/SmartDP/Search/SDPSearchForm/smartDPSearchFormSchema";
import "app/pages/SmartDP/Search/SDPSearchForm/SmartDPSearchForm.scss";

import { DESTINATION_USA_CODE, FS_QUOTE_EVENT_NAME } from "app/constants";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import { FormattedMessage } from "react-intl";
import IconClose from "app/pages/.shared/IconClose";
import { Form, Formik } from "formik";
import SmartDPSearchInput from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPSearchInput";
import LocationMenuList from "app/pages/SmartDP/Search/SDPSearchForm/LocationMenuList";
import ClearIndicatorSdpSearchInput from "app/pages/SmartDP/Search/SDPSearchForm/ClearIndicatorSdpSearchInput";
import get from "lodash/get";
import IconLocation from "app/pages/.shared/IconLocation";
import DestinationInputValueFormat from "app/pages/SmartDP/Search/SDPSearchForm/DestinationInputValueFormat";
import CalendarDisabledView from "app/pages/SmartDP/Search/SDPSearchForm/CalendarDisabledView";
import { differenceInDays } from "date-fns";
import TravellersRoomDrawerButton from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomDrawerButton";
import OccupanciesDisplayLabel from "app/pages/.shared/OccupanciesDisplayLabel/OccupanciesDisplayLabel";
import TravellersRoomButton from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomButton";
import Button from "app/pages/.shared/form/Button";
import IconMagnifyingGlass from "app/pages/.shared/IconMagnifyingGlass";
import FormErrorMessages from "app/pages/.shared/form/FormErrorMessages/FormErrorMessages";
import PropTypes from "prop-types";
import StopoverDateCalendarContainer from "app/pages/Stopover/StopoverSearchForm/StopoverDateCalendarContainer";

const HotelOnlySearchForm = ({
	onSuccess = () => {},
	destinations = [],
	initialValues = {},
	hideSidePanel = () => {},
	showMiniSDPForm = false,
	validateOnMount,
	displayLoader,
	errorScrollIntoView = true,
	onFieldFocus = () => {},
	resetAllSDPProductsFilter = () => {},
}) => {
	const { hit: fsHit } = useFlagship();

	const selectRefs = {
		destinationResort: useRef(null),
		departureDate: useRef(null),
	};
	const { pathname } = useLocation();
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const handleSubmit = useCallback(
		(values, actions) => {
			resetAllSDPProductsFilter();
			actions.setSubmitting(false);
			onSuccess({ values, pathname });
		},
		[destinations, pathname]
	);
	const [destinationInput, setDestinationInput] = useState();
	// fix pour ne pas avoir les labels sur 2 lignes au premier rendu
	const mergeInitialValues = isServerSide
		? {
				occupancies: [{ adults: 2, children: 0, childrenBirthdates: [] }],
		  }
		: {
				...defaultValues,
				...initialValues,
		  };

	const isListingOrQuote =
		pathname.includes("/hotelonly/listing") || pathname === "/hotelonly/booking/quote";
	const isMerchPage = pathname.includes("/merch");

	const onHandleClickSearchButton = useCallback(() => {
		fsHit.send({
			type: HitType.EVENT,
			category: EventCategory.ACTION_TRACKING,
			action: FS_QUOTE_EVENT_NAME.CLICK_ON_SEARCH_CTA,
		});
	}, []);

	const popoverValues = useMemo(() => {
		let values = {
			travelRoomsPopoverWidth: 0,
			travelRoomsPopoverOffset: [0, 0],
			calendarPopoverOffset: [0, 0],
			destinationPopoverWidth: 0,
			calendarPopoverWidth: 1024,
		};

		if (isListingOrQuote) {
			// Cas: isListingOrQuote
			values = {
				...values,
				travelRoomsPopoverWidth: 250,
				travelRoomsPopoverOffset: [30, 11],
				destinationPopoverWidth: 380,
				calendarPopoverOffset: [0, -9],
			};
		} else {
			values.destinationPopoverOffset = [-17, -6];
			// Cas: !isListingOrQuote
			values = {
				...values,
				travelRoomsPopoverWidth: 327,
				travelRoomsPopoverOffset: [80, 6],
				destinationPopoverWidth: 340,
				calendarPopoverOffset: [0, -9],
			};
		}

		return values;
	}, [isListingOrQuote]);

	const [refs, setRefs] = useState(null);

	const updateFloatingPositionReference = useCallback(refs => {
		setRefs(refs);
	}, []);
	const customFieldIds = ["departureDate"];

	const fieldIds = useMemo(() => ["destinationResort", "departureDate"], []);

	const fieldOrder = useMemo(() => fieldIds.map(id => ({ id, ref: selectRefs[id] })), [
		fieldIds,
		selectRefs,
	]);
	const getFieldValue = (values, fieldId) => {
		const nestedFields = {
			departureDate: ["travelDates", "departureDate"],
		};

		if (nestedFields[fieldId]) {
			return nestedFields[fieldId].reduce(
				(acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
				values
			);
		}

		return values[fieldId];
	};

	const handleFieldChange = useCallback(
		(fieldId, setFieldValue, value, values) => {
			setFieldValue(fieldId, value);

			if (value) {
				const currentIndex = fieldOrder.findIndex(field => field.id === fieldId);
				if (currentIndex === -1) {
					return;
				}

				const previousFields = fieldOrder.slice(0, currentIndex);
				const firstEmptyPrevField = previousFields.find(
					field => !getFieldValue(values, field.id)
				);

				if (firstEmptyPrevField && firstEmptyPrevField.ref.current) {
					if (customFieldIds.includes(firstEmptyPrevField.id)) {
						requestAnimationFrame(() => {
							firstEmptyPrevField.ref.current.click();
						});
					} else {
						requestAnimationFrame(() => {
							firstEmptyPrevField.ref.current.focus();
						});
					}
					return;
				}

				let nextIndex = currentIndex + 1;

				while (nextIndex < fieldOrder.length) {
					const nextField = fieldOrder[nextIndex];
					if (!getFieldValue(values, nextField.id) && nextField.ref.current) {
						if (customFieldIds.includes(nextField.id)) {
							requestAnimationFrame(() => {
								nextField.ref.current.click();
							});
						} else {
							requestAnimationFrame(() => {
								nextField.ref.current.focus();
							});
						}
						break;
					}
					nextIndex++;
				}
			}
		},
		[fieldOrder, customFieldIds]
	);

	return (
		<div className="sdp-search-form" ref={refs?.setPositionReference}>
			{(isListingOrQuote || (isMerchPage && showMiniSDPForm)) && isMobile && (
				<header className="sdp-search-form__header">
					<Typography variant={TYPOGRAPHY_VARIANTS.LARGE} isBold component="h3">
						<FormattedMessage id="sdp.search.home.input.label" />
					</Typography>
					<div className="advanced-select__header-icon">
						<IconClose width={24} height={24} onClick={hideSidePanel} />
					</div>
				</header>
			)}
			<Formik
				enableReinitialize
				initialValues={mergeInitialValues}
				validate={validateSearch(true)}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={handleSubmit}
				initialTouched={
					validateOnMount && {
						destinationResort: true,
						travelDates: {
							departureDate: true,
							endDate: true,
						},
						duration: {
							code: true,
						},
						occupancies: true,
					}
				}
				validateOnMount={validateOnMount}
			>
				{({ values, errors, isValid, isSubmitting, setFieldValue, submitCount }) => {
					const handleTravellersConfirmation = values => {
						setFieldValue("occupancies", values);
					};

					const selectedDestinationData = destinations.find(destination => {
						// can be string if from query or number if from filters.json
						return String(destination.code) === String(values?.destinationResort?.code);
					});

					const sdpRotationUri = selectedDestinationData?.departureCities?.find(
						city => city?.code === values?.departureCity?.code
					)?.uri;

					const isUsaDestination = DESTINATION_USA_CODE.includes(
						values?.destinationResort?.code
					);

					return (
						<>
							<Form className="sdp-search-form__form" data-testid="sdp-search-form">
								<SmartDPSearchInput
									menuIsOpen={Boolean(destinationInput)}
									id="destinationResort"
									name="destinationResort"
									selectRef={selectRefs.destinationResort}
									openMenuOnFocus={!values.destinationResort}
									openMenuOnClick={!values.destinationResort}
									validate={validateDestination}
									onChange={value =>
										handleFieldChange(
											"destinationResort",
											setFieldValue,
											value,
											values
										)
									}
									onFocus={onFieldFocus}
									className="sdp-search-form__field sdp-search-form__field-destination-hotel-only "
									data-testid="destination-resort-input"
									filterOption={destinationResortFilterOptions}
									label={
										<FormattedMessage id="hotel.only.search.destination.input.label" />
									}
									drawerInputLabel={
										<FormattedMessage id="sdp.search.destination.input.label.mobile" />
									}
									formatOptionLabel={({ labels = [] }) => {
										return (
											<div className="sdp-search-form__suggestion">
												<div className="sdp-search-form__suggestion-picto">
													<IconLocation />
												</div>
												<div className="sdp-search-form__suggestion-text">
													<span
														className="sdp-search-form__suggestion-first-label"
														dangerouslySetInnerHTML={{
															__html: labels?.slice(-1).join(", "),
														}}
													/>
													<span
														className="sdp-search-form__suggestion-second-label"
														dangerouslySetInnerHTML={{
															__html: labels?.slice(0, -1).join(", "),
														}}
													/>
												</div>
											</div>
										);
									}}
									components={{
										SingleValue: DestinationInputValueFormat,
										ClearIndicator: ClearIndicatorSdpSearchInput,
										MenuList: LocationMenuList,
									}}
									getOptionValue={({ code }) => code}
									getOptionLabel={({ label }) => label}
									options={sortOptions(destinations, destinationInput)}
									// on a ajouté  {|| "" } dans value de l'input pour forcer l'initialisation du champ s'il reçoit rien comme
									// valeur par defaut
									value={
										destinations.find(({ code }) => {
											return (
												code ===
												Number(get(values, "destinationResort.code"))
											);
										}) || ""
									}
									noOptionsMessage={() => {
										return <FormattedMessage id="sdp.search.no.result.label" />;
									}}
									popperWidth={popoverValues.destinationPopoverWidth}
									popperOffset={popoverValues.destinationPopoverOffset}
									locationMenuLabel={
										<FormattedMessage id="sdp.search.top.destinations.cities.label" />
									}
									isTopLocationMenuDisplayed={false}
									onInputChange={inputValue => {
										setDestinationInput(inputValue);
									}}
									loading={displayLoader}
								/>

								<StopoverDateCalendarContainer
									id="travelDates"
									name="travelDates"
									data-testid="travel-date-input"
									departureDateRef={selectRefs.departureDate}
									endDateRef={selectRefs.endDate}
									validate={validateDepartureDate}
									onFocus={onFieldFocus}
									className="sdp-search-form__field"
									popperOffset={popoverValues.calendarPopoverOffset}
									updateFloatingPositionReference={
										updateFloatingPositionReference
									}
									{...isListingOrQuote && {
										popoverWidth: popoverValues.calendarPopoverWidth,
									}}
									departureLabel={
										<FormattedMessage id="hotel.only.search.departure.date.side.panel.title" />
									}
									returnLabel={
										<FormattedMessage id="hotel.only.search.destination.date.side.panel.title" />
									}
									calendarDisabledView={
										<CalendarDisabledView
											mainMessage={
												values.departureCity && values.destinationResort ? (
													<FormattedMessage id="hotel.only.calendar.unavailable.date.label" />
												) : (
													<FormattedMessage id="hotel.only.calendar.no.destination.no.departure.city.label" />
												)
											}
											secondaryMessage={
												values.departureCity && values.destinationResort ? (
													<FormattedMessage id="sdp.calendar.unavailable.date.sublabel" />
												) : null
											}
										/>
									}
									uri={sdpRotationUri}
									handleDayChange={({
										selectedEndDate,
										selectedDepartureDate,
									}) => {
										if (selectedEndDate && selectedDepartureDate) {
											const daysDifference = differenceInDays(
												new Date(selectedEndDate),
												new Date(selectedDepartureDate)
											);
											setFieldValue("duration", {
												code: daysDifference,
											});
										}
									}}
									loading={displayLoader}
									isHotelOnly={true}
								/>
								{isMobile ? (
									<TravellersRoomDrawerButton
										className="sdp-search-form__field sdp-search-form__field-rooms sdp-search-form__field--fullsize"
										id="travellersRooms"
										valueToDisplay={
											<OccupanciesDisplayLabel
												occupancies={values.occupancies}
											/>
										}
										onConfirmation={handleTravellersConfirmation}
										initialValues={values.occupancies}
										label={
											<FormattedMessage id="sdp.search.travellers.input.label" />
										}
										validateOnMount={validateOnMount}
										warningMessage={isUsaDestination}
									/>
								) : (
									<TravellersRoomButton
										className="sdp-search-form__field sdp-search-form__field-rooms sdp-search-form__field--fullsize"
										id="travellersRooms"
										onFocus={onFieldFocus}
										valueToDisplay={
											<OccupanciesDisplayLabel
												occupancies={values.occupancies}
											/>
										}
										onConfirmation={handleTravellersConfirmation}
										initialValues={values.occupancies}
										label={
											<FormattedMessage id="sdp.search.travellers.input.label" />
										}
										stickyMode={false}
										validateOnMount={validateOnMount}
										loading={displayLoader}
										popperWidth={popoverValues.travelRoomsPopoverWidth}
										popperOffset={popoverValues.travelRoomsPopoverOffset}
										warningMessage={isUsaDestination}
									/>
								)}

								<div className="sdp-search-form__footer">
									{isListingOrQuote && !isMobile ? (
										<Button
											variant="primary"
											submit
											className="sdp-search-form__button"
											loading={isSubmitting}
											data-testid="sdp-search-form-button"
											disabled={displayLoader || isSubmitting}
										>
											<IconMagnifyingGlass />
										</Button>
									) : (
										<Button
											variant="primary"
											submit
											className="sdp-search-form__button"
											loading={isSubmitting}
											data-testid="sdp-search-form-button"
											onClick={onHandleClickSearchButton}
											disabled={displayLoader || isSubmitting}
										>
											<FormattedMessage id="sdp.search.cta.label" />
										</Button>
									)}
								</div>
							</Form>
							{/* Do not display error message on validationOnMount (redirection from emirates) */}
							{!isValid && submitCount >= 1 && (
								<div className="sdp-search-form__error">
									<FormErrorMessages
										errors={errors}
										isSubmitting={isSubmitting}
										submitCount={submitCount}
										disableErrorsDetails={true}
										message={
											<FormattedMessage id="sdp.search.form.error.message" />
										}
										scrollInToView={errorScrollIntoView}
									/>
								</div>
							)}
						</>
					);
				}}
			</Formik>
		</div>
	);
};

HotelOnlySearchForm.propTypes = {
	onSuccess: PropTypes.func,
	destinations: PropTypes.array,
	initialValues: PropTypes.object,
	hideSidePanel: PropTypes.func,
	onFieldFocus: PropTypes.func,
	showMiniSDPForm: PropTypes.bool,
	validateOnMount: PropTypes.bool,
	handleFormError: PropTypes.func,
	displayLoader: PropTypes.bool,
	errorScrollIntoView: PropTypes.bool,
	resetAllSDPProductsFilter: PropTypes.func,
};

export default memo(HotelOnlySearchForm);
